import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="chocoportrait-summary"
export default class extends Controller {
  connect() {
    this.element.querySelector('.goto-cart').addEventListener('click', this.resetCart);
  }

  openFlavourPopup() {
    this.dispatch("openFlavourPopup");
  }

  resetCart = () => {
    document.cookie = 'CURRENT_CHOCO_PORTRAIT_ID=; Max-Age=-99999999;';  
  }
  
  disconnect() {
  }
}


