import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";
import { Flip } from "gsap/Flip";
import { Observer } from "gsap/Observer";

/* The following plugin is a Club GSAP perk */
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { load } from "../utils/images";

gsap.registerPlugin(Flip)
gsap.registerPlugin(DrawSVGPlugin)
gsap.registerPlugin(Observer)

// Connects to data-controller="chocoportrait"
export default class extends Controller {
  static targets = [
    "title", "btnPrev", "mobileBtnPrev", "btnNext", "mobileBtnNext", "btnSummary", "mobileBtnSummary", "mobileBtnPreview",
    "progressWrapper", "progressSteps", "progressLineOneBrown", "progressLineOneRed", "progressLineTwoBrown", "progressLineTwoRed",
    "paginationWrapper", "mobilePaginationWrapper", "steps", "progressStepOneNumber", "progressStepOneText", "progressStepTwoNumber",
    "progressStepTwoText", "flavourInput", "themeInput", "imageInput", "messageInput", "titleInput", "editCanvas", "editCanvasWrapper",
    "previewCanvas", "previewCanvasWrapper", "imagePlaceholder", "messagePreview", 'titlePreview', "previewWrapper", "btnsWrapper",
    "previewCanImage", "previewSecondCanImage", "originalImageInput", "croppedImageInput", "form", "previewThemeWrapper", "previewImageInput"
  ];

  connect() {
    this.currentStep = 0;
    this.lastStepUnlocked = 0;
    this.hasImage = false;
    this.hasMessage = false;
    this.hasTitle = false;
    this.canvasInited = false;
    this.allowMessageWrite = true;

    this.createProgressLineAnimation();
    this.addSavedContent();
    this.preloadImages();

    this.messageInputTarget.addEventListener('input', this.handleInput);

    if (parseInt(this.formTarget.dataset.theme) > 0) {
      this.onThemeSelect({
        srcElement: [...document.querySelectorAll(".input-theme")].find(x => x.value == this.formTarget.dataset.theme)
      })
      this.changeStep(2)
      this.btnPrevTarget.classList.add("hidden");
      this.mobileBtnPrevTarget.classList.add("hidden");
      this.initCanvas()
    }
  }

  addSavedContent() {
    this.flavourInputTargets.forEach(input => {
      if (input.checked) {
        this.unlockStepTheme();
      }
    });

    this.themeInputTargets.forEach(input => {
      if (input.checked) {
        this.unlockStepImage();
        let img = document.createElement('img');
        img.src = input.dataset.bigCanImg
        img.className = "w-full h-full top-0 left-0 absolute opacity-0";
        this.previewThemeWrapperTarget.appendChild(img);
      }
    });

    if (this.imageInputTarget.value) {
      this.deleteImage();
    }

    if (this.titleInputTarget.value) {
      this.hasTitle = true;
      this.onTitleInput();
    }

    if (this.messageInputTarget.value) {
      this.hasMessage = true;
      this.onMessageInput();
    }

    this.changeStep(0, true) //To deal with turbo cache
  }

  preloadImages() {
    this.themeInputTargets.forEach((element) => {
      new Image().src = element.dataset.bigCanImg;
    })
  }

  createProgressLineAnimation() {
    gsap.set(this.progressLineOneRedTarget, {
      drawSVG: "0% 0%"
    })

    gsap.set(this.progressLineTwoRedTarget, {
      drawSVG: "0% 0%"
    })

    //Reset to deal with turbo cache
    this.progressStepOneNumberTarget.style.backgroundColor = "";
    this.progressStepOneTextTarget.style.color = "";
    this.progressStepTwoNumberTarget.style.backgroundColor = "";
    this.progressStepTwoTextTarget.style.color = "";

    this.progressLineTl = gsap.timeline({ paused: true });
    this.progressLineTl.fromTo(this.progressLineOneBrownTarget, { drawSVG: "0% 100%" }, { drawSVG: "0% 0%", duration: 0.9 })
    this.progressLineTl.to(this.progressLineOneRedTarget, { drawSVG: "100%", duration: 0.9 }, "<")
    this.progressLineTl.to(this.progressStepOneNumberTarget, { backgroundColor: "#E52318", duration: 0.3 }, ">-0.1")
    this.progressLineTl.to(this.progressStepOneTextTarget, { color: "#E52318", duration: 0.3 }, "<")
    this.progressLineTl.addLabel("middle");
    this.progressLineTl.fromTo(this.progressLineTwoBrownTarget, { drawSVG: "0% 100%" }, { drawSVG: "0% 0%", duration: 0.9 })
    this.progressLineTl.to(this.progressLineTwoRedTarget, { drawSVG: "100%", duration: 0.9 }, "<")
    this.progressLineTl.to(this.progressStepTwoNumberTarget, { backgroundColor: "#E52318", duration: 0.3 }, ">-0.1")
    this.progressLineTl.to(this.progressStepTwoTextTarget, { color: "#E52318", duration: 0.3 }, "<")
    this.progressLineTl.addLabel("end");
  }

  //TODO à faire quand le client va être pret
  onSizeButtonClick = (e) => {
    e.preventDefault();
  }

  onProgressButtonClick = (e) => {
    e.preventDefault();
    this.changeStep(parseInt(e.currentTarget.dataset.step))
  }

  onFlavourSelect = (e) => {
    if (this.lastStepUnlocked < 1) {
      this.unlockStepTheme();
    }
  }

  openPlaceholderClick = (e) => {
    e.preventDefault();
    this.imageInputTarget.click();
  }

  processInputValue = () => {
    const nextValue = this.messageInputTarget.value
      .replace(/[\n\r]+/g, '\n');
    //.trimEnd();

    const out = [];

    for (const line of nextValue.split('\n')) {
      if (line.length <= 58) {
        out.push(line)
        continue;
      }

      let chunk = "";

      for (let i = 0; i < line.length; i++) {
        const chr = line[i];

        if (i % 58 === 0 && i !== 0) {
          out.push(chunk);
          chunk = "";
        }

        chunk += chr;
      }

      if (chunk.length > 0) {
        out.push(chunk);
      }
    }

    const processedValue = (out.length >= 3 ? out.slice(0, 3) : out).join('\n');

    this.messageInputTarget.value = processedValue;
  }

  handleInput = () => {
    this.allowMessageWrite = true;

    this.processInputValue();

    this.hasMessage = this.messageInputTarget.value.length > 0;
    this.unlockStepSummary();

    this.onMessageInput();
  }


  onMessageInput = () => {
    let lines = this.messageInputTarget.value.split("\n").slice(0, 3)
    this.messagePreviewTarget.innerHTML = lines.join("<br>");
  }

  onTitleInput = () => {
    this.hasTitle = this.titleInputTarget.value.length > 0;
    this.unlockStepSummary();

    this.titlePreviewTarget.innerHTML = this.titleInputTarget.value;
  }

  onTitleEnter = (e) => {
    e.preventDefault();
  }

  onPrevButtonClick = (e) => {
    e.preventDefault();
    this.changeStep(this.currentStep == 0 ? 2 : this.currentStep - 1)
  }

  onNextButtonClick = (e) => {
    e.preventDefault();
    this.changeStep(this.currentStep == 2 ? 0 : this.currentStep + 1)
  }

  onSummaryButtonClick = async (e) => {
    e.preventDefault();

    if (this.stepChangeDisabled) {
      return
    }

    this.btnSummaryTarget.querySelector('.btn-icon .arrow').classList.add('hidden');
    this.btnSummaryTarget.querySelector('.btn-icon .spinner').classList.remove('hidden');
    this.btnSummaryTarget.disabled = true;

    this.mobileBtnSummaryTarget.querySelector('.btn-icon .arrow').classList.add('hidden');
    this.mobileBtnSummaryTarget.querySelector('.btn-icon .spinner').classList.remove('hidden');
    this.mobileBtnSummaryTarget.disabled = true;

    this.stepChangeDisabled = true;

    const croppedImage = this.generateCroppedImage();

    this.originalImageInputTarget.value = croppedImage;
    this.croppedImageInputTarget.value =  croppedImage;
    this.previewImageInputTarget.value = await this.generatePreview(croppedImage);

    this.formTarget.submit();
  }

  generateCroppedImage = () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) {
      return;
    }

    canvas.width = 1600
    canvas.height = 1600

    context.drawImage(
      this.imageObject.file,
      Math.floor(this.imageCoord.x),
      Math.floor(this.imageCoord.y),
      Math.floor(this.imageObject.width * this.imageObject.scale),
      Math.floor(this.imageObject.height * this.imageObject.scale)
    );

    const output =  canvas.toDataURL('image/png');

    canvas.width = 0;
    canvas.height = 0;

    return output
  }


  generatePreview = async (cropped) => {
    const img = await load(`${this.currentBigCanImg}?version=${new Date().getTime()}`);
    const pic = await load(cropped);

    if (!img || !pic) {
      return;
    }

    const width = 388
    const height = Math.floor((img.height / img.width) * width);

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("Can't get canvas context");
    }

    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const picSize = 214;

    ctx.drawImage(pic, Math.floor((width / 2) - (picSize / 2)), 66, picSize, picSize);
    ctx.drawImage(img, 0, 0, width, height);

    ctx.font = "bold 26px GarageGothic";
    ctx.textAlign = "center";
    ctx.fillStyle = this.titleColor;
    ctx.fillText(this.titleInputTarget.value.toUpperCase(), width / 2, 308 + 25);

    ctx.font = "bold 12px TradeGothic";
    ctx.textAlign = "center";
    ctx.fillStyle = this.descriptionColor;

    var x = width / 2;
    var y = 308 + 25 + 25;
    var lineheight = 15;
    var lines = this.messageInputTarget.value.split('\n');

    for (var i = 0; i < lines.length; i++)
      ctx.fillText(lines[i], x, y + (i * lineheight));

    const dataUrl = canvas.toDataURL('image/png');

    return dataUrl;
  }

  onPreviewBtnClick = (e) => {
    e.preventDefault();

    document.body.classList.add("--popup-opened");

    gsap.set(this.previewWrapperTarget, { zIndex: 60 });
    gsap.to(this.previewWrapperTarget, { opacity: 1, duration: 0.5 })

    gsap.to([this.paginationWrapperTarget, this.mobilePaginationWrapperTarget], { opacity: 0, duration: 0.5 })
  }

  onClosePreviewBtnClick = (e) => {
    e.preventDefault();

    document.body.classList.remove("--popup-opened");

    gsap.to(this.previewWrapperTarget, { opacity: 0, duration: 0.5 })
    gsap.set(this.previewWrapperTarget, { zIndex: -10, delay: 0.5 });
    gsap.to([this.paginationWrapperTarget, this.mobilePaginationWrapperTarget], { opacity: 1, duration: 0.5 })
  }

  onThemeSelect = (e) => {
    if (this.lastStepUnlocked < 2) {
      this.unlockStepImage();
    }

    if (this.themeTransitionTl && this.themeTransitionTl.isActive()) {
      this.themeTransitionTl.kill();
    }

    this.themeTransitionTl = gsap.timeline({
      onComplete: () => {
        const themes = Array.from(this.previewThemeWrapperTarget.querySelectorAll("img"));
        if (themes && themes.length > 1) {
          themes.slice(0, -1).forEach((element) => {
            element.remove();
          })
        }
      }
    });

    let img = document.createElement('img');
    img.src = this.currentBigCanImg = e.srcElement.dataset.bigCanImg;

    this.titleColor = e.srcElement.dataset.titleColor;
    this.descriptionColor = e.srcElement.dataset.descriptionColor;

    img.className = "w-full h-full top-0 left-0 absolute opacity-0";
    this.previewThemeWrapperTarget.appendChild(img);

    const themes = Array.from(this.previewThemeWrapperTarget.querySelectorAll("img"));
    if (themes && themes.length > 1) {
      this.themeTransitionTl.to(themes.slice(0, -1), { opacity: 0, duration: 0.3 });
      this.themeTransitionTl.to(this.titlePreviewTarget, { opacity: 0, duration: 0.3 }, "<");
      this.themeTransitionTl.to(this.messagePreviewTarget, { opacity: 0, duration: 0.3 }, "<");
    }
    this.themeTransitionTl.to(img, { opacity: 1, duration: 0.3 }, "<");
    this.themeTransitionTl.to(this.titlePreviewTarget, { opacity: 1, color: e.srcElement.dataset.titleColor, duration: 0.3 }, "<");
    this.themeTransitionTl.to(this.messagePreviewTarget, { opacity: 1, color: e.srcElement.dataset.descriptionColor, duration: 0.3 }, "<");
  }

  changeStep(step, force = false) {
    if (this.stepChangeDisabled) {
      return
    }

    if (step != this.currentStep || force) {
      this.animateProgressLine(step);
      this.showHideButtons(step);
      this.showHideSections(step);
      if (step == 2 && !this.canvasInited) {
        this.initCanvas();
      }
    }
    this.currentStep = step;
  }

  animateProgressLine(step) {
    switch (step) {
      case 0:
        this.progressLineTl.tweenTo(0)
        break;
      case 1:
        this.progressLineTl.tweenTo("middle")
        break;
      case 2:
        this.progressLineTl.tweenTo("end")
        break;
    }
  }

  showHideButtons(step) {
    gsap.to([this.paginationWrapperTarget, this.mobilePaginationWrapperTarget], { opacity: 0, duration: 0.25, y: "1rem" })
    gsap.delayedCall(0.25, () => {
      this.btnNextTarget.disabled = step >= this.lastStepUnlocked;
      this.mobileBtnNextTarget.disabled = step >= this.lastStepUnlocked;

      switch (step) {
        case 0:
          this.btnPrevTarget.classList.add("hidden");
          this.mobileBtnPrevTarget.classList.add("hidden");

          this.btnNextTarget.classList.remove("hidden");
          this.mobileBtnNextTarget.classList.remove("hidden");

          this.btnSummaryTarget.classList.add("hidden");
          this.mobileBtnSummaryTarget.classList.add("hidden");
          break;
        case 1:
          this.btnPrevTarget.classList.remove("hidden");
          this.mobileBtnPrevTarget.classList.remove("hidden");

          this.btnNextTarget.classList.remove("hidden");
          this.mobileBtnNextTarget.classList.remove("hidden");

          this.btnSummaryTarget.classList.add("hidden");
          this.mobileBtnSummaryTarget.classList.add("hidden");
          break;
        case 2:
          this.btnPrevTarget.classList.remove("hidden");
          this.mobileBtnPrevTarget.classList.remove("hidden");

          this.btnNextTarget.classList.add("hidden");
          this.mobileBtnNextTarget.classList.add("hidden");

          this.btnSummaryTarget.classList.remove("hidden");
          this.mobileBtnSummaryTarget.classList.remove("hidden");
          break;
      }
      gsap.to([this.paginationWrapperTarget, this.mobilePaginationWrapperTarget], { opacity: 1, delay: 0.1, duration: 0.25, y: 0 })
    })
  }

  showHideSections(step) {
    const state = Flip.getState(this.stepsTargets);
    this.stepsTargets.forEach((element, index) => {
      if (step == index) {
        element.classList.remove("hidden");
        this.titleTarget.innerHTML = element.dataset.title;
      } else {
        element.classList.add("hidden");
      }
    });

    Flip.from(state, {
      duration: 1.2,
      fade: true,
      absolute: false,
      onEnter: elements => gsap.fromTo(elements, { opacity: 0 }, { opacity: 1, duration: 1.2, delay: 0.2 }),
      onLeave: elements => gsap.to(elements, { opacity: 0, duration: 1.2 })
    });
  }

  unlockStepTheme() {
    this.lastStepUnlocked = 1;

    this.btnNextTarget.disabled = false;
    this.mobileBtnNextTarget.disabled = false;

    this.progressStepsTargets[1].classList.remove("pointer-events-none")
  }

  unlockStepImage() {
    this.lastStepUnlocked = 2;

    this.btnNextTarget.disabled = false;
    this.mobileBtnNextTarget.disabled = false;

    this.progressStepsTargets[2].classList.remove("pointer-events-none")
  }

  unlockStepSummary() {
    this.btnSummaryTarget.disabled = !(this.hasImage && this.hasTitle && this.hasMessage);
    this.mobileBtnSummaryTarget.disabled = !(this.hasImage && this.hasTitle && this.hasMessage);
  }

  onImageUpload = (e) => {
    this.showHideImageUi(true);
    this.unlockStepSummary();
    this.loadImage(e.target.files[0])
  }

  loadImage = (file) => {
    var reader = new FileReader();
    reader.onload = (event) => {
      var img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        this.imageObject = {
          file: img,
          URL: img.src,
          width: img.width,
          height: img.height,
          ratio: img.width / img.height,
          scale: 1
        };
        this.addImageToCanvas();
      }
    }
    reader.readAsDataURL(file);
  }

  async initCanvas() {
    this.canvasInited = true;
    this.canvasObject = {
      element: this.editCanvasTarget,
      height: 1600,
      width: 1600,
      x: 0,
      y: 0,
    };

    this.editCanvasContext = this.editCanvasTarget.getContext('2d');
    this.editCanvasContext.canvas.width = this.canvasObject.width
    this.editCanvasContext.canvas.height = this.canvasObject.height

    this.previewCanvasContext = this.previewCanvasTarget.getContext('2d');
    this.previewCanvasContext.canvas.width = this.canvasObject.width
    this.previewCanvasContext.canvas.height = this.canvasObject.height

    if (this.formTarget.dataset.image) {
      const response = await fetch(this.formTarget.dataset.image, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-store',
      })
      const file = await response.blob()

      this.onImageUpload({
        target: {
          files: [
            file
          ]
        }
      })
    }
  }

  setBaseImageCoord = () => {
    this.imageCoord = {
      x: (this.canvasObject.width / 2) - (this.imageObject.width / 2),
      y: (this.canvasObject.height / 2) - (this.imageObject.height / 2)
    };


    this.middleImageCoord = { // the middle of the image
      x: this.imageCoord.x + (this.imageObject.width / 2),
      y: this.imageCoord.y + (this.imageObject.height / 2)
    };
  }

  addImageToCanvas = () => {
    if (this.imageObject.width > this.imageObject.height) {
      this.imageObject.width = this.canvasObject.height * this.imageObject.ratio;
      this.imageObject.height = this.canvasObject.width;
    } else {
      this.imageObject.width = this.canvasObject.width;
      this.imageObject.height = this.canvasObject.width / this.imageObject.ratio;
    }

    this.setBaseImageCoord()

    this.editCanvasContext.drawImage(this.imageObject.file, this.imageCoord.x, this.imageCoord.y, this.imageObject.width, this.imageObject.height);
    this.previewCanvasContext.drawImage(this.imageObject.file, this.imageCoord.x, this.imageCoord.y, this.imageObject.width, this.imageObject.height);

    Observer.create({
      target: this.editCanvasTarget,
      type: "mouse,pointer,wheel",
      preventDefault: true,
      onDrag: this.onDrag,
      onWheel: this.onWheel
    })
  }

  onDrag = (self) => {
    this.imageCoord.x += self.deltaX * 6
    this.imageCoord.y += self.deltaY * 6;

    this.repaintCanvas();

    this.middleImageCoord = { // the middle of the image
      x: this.imageCoord.x + ((this.imageObject.width * this.imageObject.scale) / 2),
      y: this.imageCoord.y + ((this.imageObject.height * this.imageObject.scale) / 2)
    };
  }

  onWheel = (self) => {
    const newScale = this.imageObject.scale + (self.deltaY / 150);
    if (newScale > 0) {
      this.scaleImage(newScale)
    }
  }

  repaintCanvas() {
    this.editCanvasContext.clearRect(this.canvasObject.x, this.canvasObject.y, this.canvasObject.width, this.canvasObject.height);
    this.previewCanvasContext.clearRect(this.canvasObject.x, this.canvasObject.y, this.canvasObject.width, this.canvasObject.height);

    this.editCanvasContext.drawImage(
      this.imageObject.file,
      this.imageCoord.x,
      this.imageCoord.y,
      this.imageObject.width * this.imageObject.scale,
      this.imageObject.height * this.imageObject.scale
    );
    this.previewCanvasContext.drawImage(
      this.imageObject.file,
      this.imageCoord.x,
      this.imageCoord.y,
      this.imageObject.width * this.imageObject.scale,
      this.imageObject.height * this.imageObject.scale
    );
  }

  scaleImage(newScale) {
    this.imageObject.scale = newScale;
    let newW = this.imageObject.width * newScale;
    let newH = this.imageObject.height * newScale;
    let newXcoord = this.middleImageCoord.x - (newW / 2);
    let newYcoord = this.middleImageCoord.y - (newH / 2);
    this.imageCoord.x = newXcoord;
    this.imageCoord.y = newYcoord;

    this.repaintCanvas();
  }

  onZoomOut = (e) => {
    e.preventDefault();
    this.scaleImage(this.imageObject.scale - 0.035);
  }

  onZoomIn = (e) => {
    e.preventDefault();
    this.scaleImage(this.imageObject.scale + 0.035);
  }

  onCenter = (e) => {
    e.preventDefault();

    this.setBaseImageCoord()
    this.scaleImage(1);
  }

  onDelete = (e) => {
    e.preventDefault();
    this.deleteImage();
  }

  deleteImage = () => {
    this.showHideImageUi(false);
    this.unlockStepSummary();
    this.imageInputTarget.value = "";
    if (this.editCanvasContext) {
      this.editCanvasContext.clearRect(0, 0, this.editCanvasTarget.width, this.editCanvasTarget.height);
    }
    if (this.previewCanvasContext) {
      this.previewCanvasContext.clearRect(0, 0, this.previewCanvasTarget.width, this.previewCanvasTarget.height);
    }
  }

  showHideImageUi(hasImage) {
    if (!hasImage) { //Image delete, show placeholder, hide UI
      gsap.to(this.imagePlaceholderTarget, { duration: 0.25, opacity: 1, display: "flex" });
      gsap.to(this.btnsWrapperTarget, { opacity: 0, duration: 0.25, display: "none" });
    } else if (!this.hasImage && hasImage) { //Image added, hide placeholder, show UI
      gsap.to(this.imagePlaceholderTarget, { duration: 0.25, opacity: 0, display: "none" });
      gsap.to(this.btnsWrapperTarget, { duration: 0.25, opacity: 1, display: "flex" });
    }
    this.hasImage = hasImage;
  }

  disconnect() {
    this.progressLineTl.revert();

    if (this.themeTransitionTl) {
      this.themeTransitionTl.revert();
    }

    window.removeEventListener("touchend", this.removeTouchListener);
    window.removeEventListener("touchcancel", this.removeTouchListener);
    window.removeEventListener("mouseup", this.removeMouseListener);

    if (this.boundDrag) {
      window.removeEventListener('touchmove', this.boundDrag, false);
      window.removeEventListener('mousemouve', this.boundDrag, false);
    }

    this.editCanvasTarget.removeEventListener("mousedown", this.onDragStart);
    this.editCanvasTarget.removeEventListener("touchstart", this.onDragStart);
  }
}


