import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";
// Connects to data-controller="flavour-popup"
export default class extends Controller {
  static targets = ["background", "content"];

  connect() {
  }

  openPopup() {
    document.body.classList.add("--popup-opened");
    gsap.to(this.element, { display: "flex", duration: 0 });
    gsap.to(this.backgroundTarget, { duration: 0.25, opacity: 1 });
    gsap.to(this.contentTarget, { delay: 0.1, duration: 0.25, opacity: 1 });
    gsap.delayedCall(0.01, () => this.element.classList.add("fade-in--manual", "fade-in-childs--manual"));
  }

  closePopup() {
    document.body.classList.remove("--popup-opened");
    gsap.to(this.contentTarget, { duration: 0.35, opacity: 0 });
    gsap.to(this.backgroundTarget, { delay: 0.2, duration: 0.35, opacity: 0 });
    gsap.to(this.element, { delay: 0.55, display: "none", duration: 0 });
    gsap.delayedCall(0.55, () => {
      this.element.classList.remove("fade-in--manual", "fade-in-childs--manual");
    });
  }

  disconnect() { }
}
