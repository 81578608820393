import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="turnstile"
export default class extends Controller {
  async connect() {
    this.widgetId = null

    this.turnstileScript = document.createElement('script');
    this.turnstileScript.onload = () => {
      const intervalId = setInterval(() => {
        const turnstileCaptcha = document.getElementById("turnstile-captcha")

        if (!turnstileCaptcha) return

        clearInterval(intervalId)

        this.widgetId = turnstile.render('#turnstile-captcha', {
          sitekey: turnstileCaptcha.dataset.sitekey,
          callback: function (token) {
            setTimeout(() => {
              document.querySelector("#submit-button, #choco_finance_submit").disabled = false
              document.getElementById("turnstile-token").value = token
              turnstile.remove(this.widgetId)
              this.widgetId = null
            }, 1000);
          },
        });

      }, 1000);
    }
    this.turnstileScript.setAttribute('src', "https://challenges.cloudflare.com/turnstile/v0/api.js");
    document.body.appendChild(this.turnstileScript);
  }

  disconnect() {
    turnstile.remove(this.widgetId)

    this.widgetId = null
    this.turnstileScript.innerHTML = '';

    delete window.turnstile
  }

}
